<template>
  <v-dialog
    overlay-color="rgba(0,0,0,0.4)"
    overlay-opacity="0.8"
    v-model="state"
    max-width="480"
    persistent
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(addBranchAdmin)" class="px-3">
        <v-card flat ref="form">
          <v-card-title>
            <span class="ft font-size-lg font-weight-bold blue-grey--text ml-1"
              >Add Branch Admin</span
            >
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    autofocus
                    v-model="branchAdmin.name"
                    label="Admin Name"
                    class="ft font-weight-medium font-size-sm"
                    :error-messages="errors[0]"
                    hint="Name of the admin user"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required|email"
                >
                  <v-text-field
                    outlined
                    class="ft font-weight-medium font-size-sm"
                    v-model="branchAdmin.email"
                    label="Email Address"
                    :error-messages="errors[0]"
                    hint="Admin's email address here"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    class="ft font-weight-medium font-size-sm"
                    v-model="branchAdmin.username"
                    label="Username"
                    :error-messages="errors[0]"
                    hint="Enter admin's username here.."
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    class="ft font-weight-medium font-size-sm"
                    v-model="branchAdmin.mobile"
                    label="Mobile Number"
                    :error-messages="errors[0]"
                    hint="Admin's mobile number here"
                  />
                </ValidationProvider>
                <span class="red--text ft font-weight-medium font-size-sm"
                  >NB. A temporary password will be sent to your email
                  address</span
                >
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="close"
              class="ft font-weight-medium font-size-sm text-capitalize"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              type="submit"
              :loading="loading"
              :disabled="loading"
              color="primary"
              class="ft font-weight-medium font-size-sm text-capitalize"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { email, numeric, required } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";

extend("required", {
  ...required,
  message: "Field is required"
});
extend("email", {
  ...email,
  message: "Invalid email provided"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
export default {
  name: "AddBranchAdmin",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    ...mapGetters({
      roles: "roles/getDefaultRoles",
      resetFormState: "getResetFormState"
    })
  },
  props: {
    state: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    branchAdmin: {
      name: "",
      mobile: "",
      email: "",
      username: "",
      isAdminUser: true
    },
    reset: {
      name: "",
      mobile: "",
      email: "",
      username: ""
    }
  }),
  watch: {
    resetFormState(value) {
      if (value) {
        this.branchAdmin = { ...this.reset };
      }
      setTimeout(() => {
        this.$store.dispatch("resetFormState", false, { root: true });
      }, 2000);
    }
  },
  methods: {
    close() {
      this.$emit("actions", "add");
    },
    addBranchAdmin() {
      this.$emit("branchAdmin", this.branchAdmin);
    }
  },
  created() {}
};
</script>
