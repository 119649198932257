var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"rgba(0,0,0,0.4)","overlay-opacity":"0.8","max-width":"480","persistent":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addBranchAdmin)}}},[_c('v-card',{ref:"form",attrs:{"flat":""}},[_c('v-card-title',[_c('span',{staticClass:"ft font-size-lg font-weight-bold blue-grey--text ml-1"},[_vm._v("Add Branch Admin")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-col',[_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-sm",attrs:{"outlined":"","autofocus":"","label":"Admin Name","error-messages":errors[0],"hint":"Name of the admin user"},model:{value:(_vm.branchAdmin.name),callback:function ($$v) {_vm.$set(_vm.branchAdmin, "name", $$v)},expression:"branchAdmin.name"}})]}}],null,true)})],1),_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-sm",attrs:{"outlined":"","label":"Email Address","error-messages":errors[0],"hint":"Admin's email address here"},model:{value:(_vm.branchAdmin.email),callback:function ($$v) {_vm.$set(_vm.branchAdmin, "email", $$v)},expression:"branchAdmin.email"}})]}}],null,true)})],1),_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-sm",attrs:{"outlined":"","label":"Username","error-messages":errors[0],"hint":"Enter admin's username here.."},model:{value:(_vm.branchAdmin.username),callback:function ($$v) {_vm.$set(_vm.branchAdmin, "username", $$v)},expression:"branchAdmin.username"}})]}}],null,true)})],1),_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-sm",attrs:{"outlined":"","label":"Mobile Number","error-messages":errors[0],"hint":"Admin's mobile number here"},model:{value:(_vm.branchAdmin.mobile),callback:function ($$v) {_vm.$set(_vm.branchAdmin, "mobile", $$v)},expression:"branchAdmin.mobile"}})]}}],null,true)}),_c('span',{staticClass:"red--text ft font-weight-medium font-size-sm"},[_vm._v("NB. A temporary password will be sent to your email address")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ft font-weight-medium font-size-sm text-capitalize",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ft font-weight-medium font-size-sm text-capitalize",attrs:{"type":"submit","loading":_vm.loading,"disabled":_vm.loading,"color":"primary"}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }